/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import useSelectPrimary from '../../shared/hooks/use-selectPrimary';
import Select from "react-select";
import { uiActions } from '../../shared/store/ui-Slice';

const LangSelect = ({ languages, langValue, setSelectLanguage, setLangFocus }: any) => {
    const theme: any = useSelector((state: any) => state.ui.theme);
    const lang: string = useSelector((state: any) => state.ui.lang);
    let styles = useSelectPrimary(theme);
    const dispatch = useDispatch();

    const handleChange = (e: any) => {
        if (lang !== e?.value) {
            setSelectLanguage(e);
            dispatch(uiActions.setLangOpen(true));
        }
    }

    return (
        <div className='language'>
            <Select options={languages} styles={styles} className="!w-full" onChange={(e: any) => handleChange(e)} value={langValue} isSearchable={false} onFocus={() => setLangFocus(true)} onBlur={() => setLangFocus(false)} aria-label="Select Language" />
        </div>
    );
};

export default LangSelect;