import React, { useState } from "react";
import { CloseIcon, MenuArrow } from "../svg/AllSvg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";
import { dataActions } from "../../shared/store/data-Slice";

const Sidebar = ({ sidebarOpen, setSidebarOpen, menu, selectedMenu }: any) => {
    const currentCity: string = useSelector((state: any) => state.data.currentCity);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const color: any = useSelector((state: any) => state.ui.color);
    const [selectMenu, setSelectMenu] = useState<any>(0);
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const handleNavigate = async (url: any) => {
        let route: any = await url?.replace(`/${lang}/`, "");
        !!params?.lang ? navigate(`/${params?.lang + route}`) : navigate(route);
        setSidebarOpen(false);
    }

    const handleLink = (url: any) => {
        setSidebarOpen(false);
        dispatch(uiActions.setWebOpen(true));
        dispatch(dataActions.setLink(url));
    }

    return (
        <div className={`fixed top-0 right-0 left-0 z-50  ${!sidebarOpen ? "-translate-x-full" : ""} !h-screen w-full xxs:w-[350px] bg-white dark:bg-[#272727] shadow-lg transition duration-500 xl:hidden focus:!outline-none`}>

            <div className="py-[14px] px-[14px] flex w-full items-end justify-end">
                <div className="cursor-pointer" onClick={() => setSidebarOpen(false)}>
                    <CloseIcon className={`${!!currentCity ? color[currentCity]?.strokePrimary : "stroke-primary"}`} />
                </div>
            </div>

            <ul className="flex flex-col w-full h-full overflow-y-scroll">
                {Array.isArray(menu) &&
                    menu.map((item: any, index: number) => (
                        <React.Fragment key={index} >
                            <li className={`flex flex-col min-h-[60px] px-[20px] w-full items-center cursor-pointer rounded-[10px] text-sm hover:bg-[#f4f4f4] dark:hover:!bg-[#858585] ${item?.title === selectedMenu?.mainmenu ? `${!!currentCity ? `${color[currentCity]?.textPrimary} font-extrabold dark:!text-[#ffff00]` : "text-primary font-extrabold"}` : "text-secondary font-normal"}`}  onClick={() => {(!!item?.url && item?.subRoutes?.length === 0) && handleNavigate(item?.url); setSelectMenu(selectMenu === (index + 1) ? 0 : (index + 1));}}>
                                <div className="flex w-full justify-between items-center h-full">
                                    <div className="h-full w-full flex items-center">
                                        {item?.title}
                                    </div>
                                    {item?.subRoutes?.length > 0 &&
                                        <div className="w-[50px] flex items-center justify-center">
                                            <div className={`${((index + 1) === selectMenu) && "active-caret"} caret h-full flex items-center justify-center`}>
                                                <MenuArrow className="dark:fill-white fill-secondary w-[12px] h-[12px] xl:w-[14px] xl:h-[14px]" />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </li>
                            <>
                                {(((index + 1) === selectMenu) && item?.subRoutes?.length > 0) &&
                                    <ul className='bg-white w-full dark:bg-[#272727]'>
                                        {Array.isArray(item?.subRoutes) && item?.subRoutes?.map((i: any, idx: number) => (
                                            <li key={idx} className={`${selectedMenu?.submenu === i?.title ? `${!!currentCity ? `text-white ${color[currentCity]?.bgPrimary}` : "bg-primary text-white"} dark:!text-[#ffff00]` : "text-secondary"} flex items-center justify-start px-[20px] cursor-pointer min-h-[60px] text-sm font-normal ${!!currentCity ? color[currentCity]?.hoverBgPrimary : "hover:bg-primary"} hover:text-white hover:dark:text-primary w-full`} onClick={() => i?.url_type === "external" ? handleLink(i?.url?.replace(`/${lang}/`, "")) : handleNavigate(i?.url)}>
                                                {i?.title}
                                            </li>
                                        ))}
                                    </ul>
                                }
                            </>
                        </React.Fragment>

                    ))}
            </ul>
        </div>
    );
};

export default Sidebar;